app.controller('vlilleController', function($http, $scope, vlilleFactory) {

  //$scope.clients = clientFactory.getAll();
  vlilleFactory.getAll().then(function(opendata) {
    //console.log(opendata.data.records);
    var data = opendata.data.records;
    $scope.data = data;
    vlilleFactory.set(opendata.data.records);

    $scope.userLatitude = 'test';
    $scope.userLongitude = 'test';

    var optionsGEOloc = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    /*
    navigator.geolocation.getCurrentPosition(
      function() {
        console.log("success")
      },
      function() {
        console.log("error")
      }
    );
*/

    google.maps.event.addDomListener(window, 'load', init());

    function init() {
      if (navigator.geolocation) {
        //console.log(navigator.geolocation.__proto__);
        navigator.geolocation.getCurrentPosition(function(param) {
          //console.log(param);
          var lat = param.coords.latitude;
          var lng = param.coords.longitude;
          $scope.userLatitude = lat;
          $scope.userLongitude = lng;
          $scope.directionsService = new google.maps.DirectionsService();
          $scope.directionsDisplay = new google.maps.DirectionsRenderer();
          gMap(lat, lng);
          geoLoc(lat, lng)
        }, function(param) {
          //console.log(param);
          gMap(50.633333, 3.066667);
        }, optionsGEOloc);
      } else {
        console.log('no geo');
        $scope.userLatitude = 50.633333;
        $scope.userLongitude = 3.066667;
      }

    }
  });

  function geoLoc(lat, lng) {
    var geocoder = new google.maps.Geocoder();
    var map = $scope.map;

    var latlng = {
      lat: lat,
      lng: lng
    };

    $scope.originiGeo = latlng;

    geocoder.geocode({
      location: latlng
    }, function(results, status) {

      //  console.log(results);
      $scope.$apply(function() {
        $scope.geoLocVille = results[1].formatted_address;
        //$scope.geoLocAdresse = results[0].formatted_address;
      })

      if (status == google.maps.GeocoderStatus.OK) {

        var myResult = results[0].geometry.location;
        map.setCenter(myResult);
        //console.log(map);

        // Affichage d'un marqueur à la position de la personne
        var marker = new google.maps.Marker({
          position: latlng,
          map: map,
          label: 'Vous êtes ici!'
        });

        map.setZoom(17);
      } else {
        console.log('erro');
      }
    });
  }

  var fenetreInfos = new google.maps.InfoWindow({
    content: ''
  });

  function gMap(lat, lng) {
    // Création de la map
    var centerMap = new google.maps.LatLng(lat, lng);
    var mapOptions = {
      zoom: 12,
      center: centerMap,
      styles: [{
        'featureType': 'administrative',
        'elementType': 'all',
        'stylers': [{
          'visibility': 'on'
        }, {
          'lightness': 33
        }]
      }, {
        'featureType': 'landscape',
        'elementType': 'all',
        'stylers': [{
          'color': '#f2e5d4'
        }]
      }, {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [{
          'color': '#c5dac6'
        }]
      }, {
        'featureType': 'poi.park',
        'elementType': 'labels',
        'stylers': [{
          'visibility': 'on'
        }, {
          'lightness': 20
        }]
      }, {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [{
          'lightness': 20
        }]
      }, {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [{
          'color': '#c5c6c6'
        }]
      }, {
        'featureType': 'road.arterial',
        'elementType': 'geometry',
        'stylers': [{
          'color': '#e4d7c6'
        }]
      }, {
        'featureType': 'road.local',
        'elementType': 'geometry',
        'stylers': [{
          'color': '#fbfaf7'
        }]
      }, {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [{
          'visibility': 'on'
        }, {
          'color': '#acbcc9'
        }]
      }]
    };
    var mapElement = document.getElementById('map');
    var map = new google.maps.Map(mapElement, mapOptions);

    $scope.directionsDisplay.setMap(map);
    $scope.directionsDisplay.setOptions({
      suppressMarkers: true
    });

    $scope.$apply(function() {
      $scope.map = map;
    });

    $scope.data.forEach(function(data) {
      // Affichage des points sur la map
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(data.fields.geo[0], data.fields.geo[1]),
        icon: {
          url: 'assets/img/velo.png',
          scaledSize: new google.maps.Size(55, 55)
        },
        animation: google.maps.Animation.DROP,
        map: map,
        lat: data.fields.geo[0],
        long: data.fields.geo[1],
        title: data.fields.nom,
        nom: data.fields.nom,
        adresse: data.fields.adresse,
        nbPlacesDispo: data.fields.nbPlacesDispo,
        nbVelosDispo: data.fields.nbVelosDispo,
        tempsEcoule: data.record_timestamp
      });
      google.maps.event.addListener(marker, 'click', function() {
        var latDest = this.lat;
        var longDest = this.long;


        var contenu = '<div class="infosFenetre">Nom: ' + this.nom + '<br>' +
          'Adresse: ' + this.adresse + '<br>' +
          'Nombre de places total: ' + (this.nbVelosDispo + this.nbPlacesDispo) + '<br>' +
          'Nombre de vélos disponible: ' + this.nbVelosDispo + '<br>' +
          'Nombre de places disponible: ' + this.nbPlacesDispo + '<br>' +
          "<span class='letsGo'><button class='btn btn-vlille' onclick='window.callRouteMaker(" + latDest + "," + longDest + ")'> Y aller</button></span>" +
          '</div>';
        fenetreInfos.setContent(contenu);
        fenetreInfos.open(map, this);
        var infos = this;
        $scope.$apply(function() {
          $scope.infos = infos;
        })
      });
    });
  }

  window.callRouteMaker = function callRouteMaker(latDest, longDest) {
    calculateAndDisplayRoute($scope.directionsService, $scope.directionsDisplay, {
      lat: $scope.userLatitude,
      lng: $scope.userLongitude
    }, {
      lat: latDest,
      lng: longDest
    });
  }

  function calculateAndDisplayRoute(directionsService, directionsDisplay, origin, dest) {
    directionsService.route({
      origin: origin,
      destination: dest,
      travelMode: google.maps.TravelMode.WALKING
    }, function(response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        console.log(response);
        directionsDisplay.setDirections(response);
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    });
  }

});
